import "../../styles/sofidel/sofidel.scss";
import {SmartComponentManager} from "smart-component-js";
import DefaultCtrl from "./controllers/DefaultCtrl";
import $ from 'jquery';

import HeaderComponent from "../common/components/HeaderComponent";
import HomeComponent from "./components/HomeComponent";
import MyActivitiesComponent from "./components/MyActivitiesComponent";
import DigitalRewardsComponent from "./components/DigitalRewardsComponent";
import ProductCarouselComponent from "./components/ProductCarouselComponent";
import ReceiptComponent from "./components/ReceiptComponent";
import PrizesRequestComponent from "./components/PrizesRequestComponent";
import RewardListComponent from "./components/RewardListComponent";
import PrizeRequestConfirmComponent from "./components/PrizeRequestConfirmComponent";
import InstantWinComponent from "./components/InstantWinComponent";
import RewardFormComponent from "./components/RewardFormComponent";
import FooterComponent from "../common/components/FooterComponent";


SmartComponentManager.registerComponents({
    DefaultCtrl,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MyActivitiesComponent,
    DigitalRewardsComponent,
    ProductCarouselComponent,
    ReceiptComponent,
    PrizesRequestComponent,
    RewardListComponent,
    PrizeRequestConfirmComponent,
    InstantWinComponent,
    RewardFormComponent
});

$(document).ready(() => {
    let $body = $('body [component="DefaultCtrl"]');
    SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
});


import {SmartComponent} from "smart-component-js";

class InstantWinComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.userName = this.element.dataset.name;
        this.email = this.element.dataset.email;
        this.init();
    }

    appendResultHtml(data) {
        const messageWrapper = this.element.querySelector('.yxpType_TEXTAREA');
        messageWrapper.innerHTML = this.getMessageFromResult(data.WINNER);

        const imageWrapper = this.element.querySelector('.yxpType_IMAGE');
        const url = data.WINNER ? '/fe-web/img/sofidel/you-win.png' : '/fe-web/img/sofidel/you-lose.png';
        imageWrapper.innerHTML = `<img alt="instant win result" src="${url}"/>`;

        if (data.WINNER) this.appendPrize(data.REWARDEXT);
    }

    appendPrize(reward) {
        const prizeHtml = `
            <div class="instant-win__prize">
                <div class="instant-win__image">
                    <img src="${reward.image}" alt="${reward.name}"/>
                </div>
                <div class="instant-win__prize-name">${reward.name}</div>
                <div>${reward.description || ''}</div>
            </div>
        `;

        this.element.querySelector('.instant-win__prize-wrapper').insertAdjacentHTML('beforeend', prizeHtml);
        this.element.querySelector('.yxpType_LINK').insertAdjacentHTML('afterend',
            '<p class="instant-win__disclaimer">We reserve the right to check the validity of the data within the time limits specified in the terms and conditions.</p>'
        );
    }


    getMessageFromResult(winner) {
        if (winner) return `
            <h1>You <i orange>win!</i></h1>
            <h3>Congratulations ${this.userName}!</h3>
            <p class="instant-win__description">You will be contacted at <b>${this.email}</b> with all the details to receive <b>your prize.</b></p>`;
        return `
            <h1>You <i orange>lose.</i></h1>
            <h3>Oh no ${this.userName}!</h3>
            <p class="instant-win__description">This time it wasn’t your day. <b>Try again</b> and remember you could still be the winner of the <b>final draw.</b></p>`;
    }

    playInstantWin() {
        this.element.classList.add('loading');
        const endpoint = `/api/1.0/sofidel/${window.CAMPAIGN_CODE}/reward/instantwin/${this.receiptId}`;
        return youserxp.ajax(endpoint, {
            method: 'POST',
            auth: true
        }).then((data) => {
            this.appendResultHtml(data.body);
            this.element.classList.remove('loading');
        }, () => {
            const messageWrapper = this.element.querySelector('.yxpType_TEXTAREA');
            messageWrapper.innerHTML = `<h3>Ops...</h3>
            <p class="instant-win__description">You have already played with this receipt. Upload another receipt and try again!</p>`;
            this.element.classList.remove('loading');
        });
    }

    init() {
        let params = new URLSearchParams(document.location.search);
        this.receiptId = params.get("id");
        if (this.receiptId) this.playInstantWin();
    }
}

export default InstantWinComponent;
import {SmartComponent} from "smart-component-js";

class RewardFormComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.rewardItemId = this.element.dataset.rewardItemId;
        this.init();
    }

    setRewardItem() {
        this.element.querySelector('input[name="idRewardItem"]').value = this.rewardItemId;
        const event = new Event('keyup');
        document.querySelector('input[name="idRewardItem"]').dispatchEvent(event);
    }

    prefillUserData() {
        window.authProvider.auth.getLoginStatus().then((d) => {
            this.form = window.youserxp.forms[$(this.element).find(".yxpType_FORM").data("yxpFormId")];
            this.form.userId = d.body.id;
            this.element.querySelector('input[name="name"]').value = d.body.name;
            this.element.querySelector('input[name="surname"]').value = d.body.surname;
            this.element.classList.remove('loading');
            this.form.checkRules();
        }, (e) => this.element.classList.remove('loading'));
    }


    init() {
        this.element.classList.add('loading');
        document.addEventListener("yxp-form-loaded", () => {
            this.setRewardItem();
            this.prefillUserData();
        });
    }
}

export default RewardFormComponent;
import {SmartComponent} from "smart-component-js";

class ReceiptComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.activePoints = this.element.dataset.activePoints;
        this.init();
    }


    toggleLabelVisibility() {
        document.addEventListener('yxp-loyalty-receipt-file-uploaded', () => {
            this.element.querySelector('.first-step').classList.add('hidden');
            this.element.querySelector('.second-step').classList.remove('hidden');
            this.element.querySelector('.yxp-rw-step.intro').classList.add('hidden');
            this.sendTracking();
        });
    }

    addThankYouPageListener() {
        document.addEventListener("yxp-loyalty-receipt-ok", (e) => {
            this.element.innerHTML = this.getThankYouPage(e.detail.body);
        });
    }

    getThankYouPage(data) {
        return `
            <div class="ty-receipt-invoice">
                <div class="ty-receipt-invoice__header">
                    <h1>Well done. <br>Now try <i blue>your luck!</i></h1>
                </div>
                
                ${data.missionOutput ? `
                    <div class="ty-receipt-invoice__card">
                        <div>
                            You have bought eligible Regina products for   
                            <span class="pound-chips-inverted"><span>${data.missionOutput.EARNED_POINTS} <i>pounds</i></span></span>
                        </div>
                        <b class="ty-receipt-invoice__label">You earned</b>
                        <div class="ty-receipt-invoice__points point-chips-inverted"><span> +${data.missionOutput.EARNED_POINTS} <i>points</i></span></div>
                        ${this.activePoints ? `<div class="ty-receipt-invoice__total"> Total points <i>${data.missionOutput.EARNED_POINTS + parseInt(this.activePoints)}</i></div>` : ''}
                    </div>
                ` : ''}
                
                <img class="ty-receipt-invoice__image" src="/fe-web/img/sofidel/icons/finger-crossed.png" alt="instant win"/>
                <h3>Play the <i orange>Instant win game</i> to find out if you have won</h3>
                <a href="/en/reserved/instant-win?id=${data.id}" class="ty-receipt-invoice__button">Play instant win</a>
            </div>
        `;
    }

    sendTracking() {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "content_upload", category: "UPLOAD", action: "TICKET", label: location.href
            });

            youserxp.tracker.getInstance().send('EVENT', {
                eventCategory: "UPLOAD", eventAction: "TICKET", eventLabel: location.href
            });
        } catch (e) {
            console.log("Tracking: " + e);
        }
    }

    init() {
        this.toggleLabelVisibility();
        this.addThankYouPageListener();
    }
}

export default ReceiptComponent;
import {SmartComponent} from "smart-component-js";

class MyActivitiesComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.campaignCode = element.dataset.campaignCode;
        this.endpoint = '/api/1.0/sofidel/' + this.campaignCode + '/activities';
        this.init();
    }

    appendItems(data) {
        let items = '';
        data.forEach((item, index) => {
            items += `
                <div class="my-activities__item ${item.icon ? 'product' : ''} ${item.earnedPoints && item.earnedPoints < 0 ? 'prize' : ''}"> <!-- prize -->
                    <div class="my-activities__info">
                        <div>
                            <span class="my-activities__label">${item.title}</span>
                            <span class="my-activities__date">${item.formattedDate}</span>
                        </div>
                        <div class="my-activities__right-wrapper">
                            ${item.description2 ? `<span class="my-activities__status ${item.description2}">
                                ${this.getStatusLabel(item.description2)}</span>` : ''}
                            ${item.earnedPoints ? `<span class="my-activities__point">${item.earnedPoints}</span>` : ''}
                        </div>
                    </div>
                    
                    ${item.icon ? `
                        <div class="my-activities__product">
                            <div class="my-activities__img">
                                <img src="${item.icon}" alt="Regina"/>
                            </div>
                            <div class="my-activities__product-name">
                                ${item.subtitle || ''} <br> ${item.description || ''}
                            </div>
                        </div>
                    ` : ''}
                    
                    ${item.cta ? `<a href="${item.cta[0].url}" target="${item.cta[0].target}" 
                        class="my-activities__link ${item.cta[0].type === 'reward-request' ? 'blue-link' : ''}">${item.cta[0].label}</a>` : ''}
                    
                </div>
                ${index === data.length - 1 ? '' : '<div class="my-activities__separator"></div>'}
            `;
        });

        this.element.querySelector('.my-activities__wrapper').insertAdjacentHTML('beforeend', items);
        this.element.classList.remove('loading');
    }

    getStatusLabel(status) {
        if (status === 'DRAFT') return 'TO BE REQUESTED';
        if (status === 'SENT') return 'PROCESSED';
        return status;
    }


    appendNoResults() {
        this.element.querySelector('.my-activities__no-results').classList.remove('hidden');
        this.element.classList.remove('loading');
    }

    getActivities() {
        youserxp.ajax(this.endpoint, {
            method: 'GET',
            auth: true
        }).then(
            (data) => data.body.results.length ? this.appendItems(data.body.results) : this.appendNoResults(),
            (err) => console.log(err),
            () => this.element.classList.remove('loading')
        );
    }

    init() {
        this.element.classList.add('loading');
        this.getActivities();
    }
}

export default MyActivitiesComponent;